export type Config = {
  key: string;
  field: string;
  lookup?: string;
  type?: 'array' | 'object' | 'string';
  isOptional?: boolean;
};

export function transformData(data: any, config: Config[]): Record<string, any> {
  const result: Record<string, any> = {};

  for (const item of config) {
    const { key, field, lookup, isOptional } = item;
    const fieldParts = field.split('.');

    let value = data;
 
    for (const part of fieldParts) {
      if (part.includes('[]')) {
        const arrayField = part.replace('[]', '');
        value = value?.[arrayField];

        if (!Array.isArray(value)) {
          throw new Error(`Expected an array for key: ${key}`);
        }

        if (lookup) {
          const matchedItem = value.find((obj: any) => obj[lookup] === key);
          value = matchedItem?.[fieldParts.slice(-(fieldParts.length-1)).join('.')]
        } else {
          value = value.map((item: any) => item[arrayField]);
        }
        break; 
      } else {
        value = value?.[part];
      }

      if (value === undefined) {
        if (isOptional) break;
        throw new Error(`Missing required field: ${field}`);
      }
    }

    if (value !== undefined) {
      result[key] = value;
    }
  }

  return result;
}

