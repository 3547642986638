import { Box, Typography } from "@mui/material";

interface Props{
    value?:string;
    clickable?:boolean;
    onClick?: () => void;
}
const APNumberCell:React.FC<Props> = ({value, clickable=false, onClick}) => {
    return(
        <Box
            sx={{
              height: '100%',
              alignContent: 'center',
              cursor: clickable?'pointer':'default',
            }}
            onClick={onClick}
          >
            <Typography>{value??'0'}</Typography>
          </Box>
    )

}

export default APNumberCell;