type FlattenedObject = { [key: string]: any };

export function flattenObject(obj: any, prefix = ''): FlattenedObject {
  return Object.keys(obj).reduce((acc: FlattenedObject, k: string): FlattenedObject => {
    const pre = prefix.length ? `${prefix}.${k}` : k;
    let value = obj[k];

    // Check if the value is a JSON string and parse it
    if (typeof value === 'string') {
      try {
        const parsedValue = JSON.parse(value);
        if (typeof parsedValue === 'object' && parsedValue !== null) {
          value = parsedValue;
        }
      } catch {
        // Keep the original string if parsing fails
      }
    }

    // Handle arrays differently based on content
    if (Array.isArray(value)) {
      value.forEach((item) => {
        // If array items are objects with 'estimate_type'
        if (typeof item === 'object' && item !== null && 'estimate_type' in item) {
          acc[`${pre}.${item.estimate_type}`] = item.total_value ?? item.estimate_type;
        }
        // If array items are primitive values
        else if (typeof item === 'string' || typeof item === 'number') {
          acc[`${pre}.${item}`] = item;
        }
      });
    }
    // Handle nested objects
    else if (typeof value === 'object' && value !== null) {
      Object.assign(acc, flattenObject(value, pre));
    }
    // Handle primitive values
    else {
      acc[pre] = value;
    }

    return acc;
  }, {});
}
