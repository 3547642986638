import { Avatar, AvatarGroup, Box, Popover, Typography } from "@mui/material";
import { useState } from "react";

interface Props {
  data?: { id:string; name: string; logo: string }[];
}

const AvatarCell: React.FC<Props> = ({ data = [] }) => {
  const [hoveredName, setHoveredName] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLElement>(null);
  const [moreItems, setMoreItems] = useState<any[]|null>();

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>, item: any) => {
    setHoveredName(item.name);
    setAnchorEl(event.currentTarget);
    setMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setHoveredName(null);
    setAnchorEl(null);
  };

  const handleMoreClicked = (event: React.MouseEvent<HTMLElement>, item: any) => {
    if(moreAnchorEl){
      setMoreAnchorEl(null);
      setMoreItems(null);
      return;
    }
    setMoreItems(data.slice(-item));
    setMoreAnchorEl(event.currentTarget);
  };

  const handleMoreMenuClose = () => {
    setMoreAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const openMore = Boolean(moreAnchorEl);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AvatarGroup 
            max={5} 
            spacing="small"
            renderSurplus={(surplus) => <span 
              onClick={(event)=>handleMoreClicked(event,surplus)}
              aria-owns={openMore ? 'more-mouse-over-popover' : undefined}
              aria-haspopup="true"
              >+{surplus.toString()[0]}</span>}
        >
          {data.map((item) => (
              <Avatar
                key={item.id}
                alt={item.name}
                src={item.logo}
                sx={{ width: 40, height: 40 }}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={(event) => handleMouseEnter(event, item)}
                onMouseLeave={handleMenuClose}
              >
                {item.name[0].toUpperCase()}
              </Avatar>
          ))}
        </AvatarGroup>
        
        <Popover
          id="mouse-over-popover"
          sx={{ pointerEvents: 'none' }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handleMenuClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: '4px', fontSize:'11px' }}>{hoveredName}</Typography>
        </Popover>

        <Popover
          id="more-mouse-over-popover"
          sx={{ pointerEvents: 'none' }}
          open={openMore}
          anchorEl={moreAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handleMoreMenuClose}
          disableRestoreFocus
        >
          {
            moreItems?.map((item)=>(
              <Typography sx={{ p: '4px', fontSize:'11px' }}>{item.name}</Typography>
            ))
          }
        </Popover>
      </Box>
    </Box>
  );
};

export default AvatarCell;
