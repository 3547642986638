import { Box, Typography } from "@mui/material";
import { ITableImageText } from "./tableInterfaces";

interface Props{
    data?: ITableImageText;
    clickable?:boolean;
    onClick?: () => void;
}
const APImageText2:React.FC<Props> = ({data, clickable, onClick}) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent:'center', height:'102px', gap: 1, cursor: clickable?'pointer':'default' }} onClick={onClick}>
            <Box sx={{width:'92px',height:'100%', backgroundColor:'#D9D9D9', objectFit:'cover'}}>
                {data?.image && <img loading="lazy" src={data?.image} alt={data?.label} width={'100%'} height={'100%'}/>}
            </Box>
            {/* <Typography>{data?.label}</Typography> */}
        </Box>
    )
}

export default APImageText2;