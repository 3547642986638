import { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import APDivider from '../../../components/APDivider';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { LabelCheckboxInput, RadioInput, RichTextInput, SelectInput } from '../../../components/inputs';
import { CAPTURE_MODE_DATA, EVALUATION_REASONS, PROCESS_DATA } from '../../../data/selectData';
import LabelInput from '../../../components/inputs/LabelInput';
import { SubmitHandler, useForm } from 'react-hook-form';
// import { isEmail, isRequired } from '../../../utils/validationRules';
import { APIconButton } from '../../../components/buttons';
import { Delete } from '@mui/icons-material';
import { IUser } from '../../auth/authInterfaces';
import { useFetchUsers } from '../../../hooks/useFetchUsers';
// import FileInput from '../../../components/inputs/FileInput';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import DeleteConfirmationDialog from '../../../components/dialogs/DeleteConfirmationDialog';
import AddViewerForm from './AddViewerForm';
import { IUserForm } from '../../user-management/userInterfaces';
import { RequestFormValues, UpdateRequestVariables } from '../requestInterfaces';
import { useMutation, useQuery } from '@tanstack/react-query';
import { deleteRequest, getRequest, updateRequest } from '../requestQueries';
import { toast } from 'react-toastify';
import { handleError } from '../../../utils/errorHandler';
import { isEmail, isRequired } from '../../../utils/validationRules';
import { useGeneralStore } from '../../../generaStore';
import { VALUATION_TYPES } from '../../../Constant';
import { useAuthStore } from '../../auth/authStore';


const RequestEditForm = () => {
  const authUser = useAuthStore.getState().authUser;
  const { control, handleSubmit, reset, formState: { dirtyFields } } = useForm<RequestFormValues>();
  const { data: response } = useFetchUsers({limit:50000,'filters[role]':'appraiser'});
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const setIsPageTransition = useGeneralStore((state) => state.setIsPageTransition);
  const [block, setBlock]  = useState(true);
  
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddViewerDialog, setOpenAddViewerDialog] = useState(false);
 
  // Fetch request data using react-query
  const { data: requestData, isLoading } = useQuery(
    {
      queryKey:['request', id], 
      queryFn:() => getRequest(id as string), 
      enabled: !!id,
    }
  );

  const { isPending: isUpdating, mutate: updateRequestMutation } = useMutation({
    mutationKey: ['updateRequestMutation'],
    retry:2,
    mutationFn: ({ requestId,formValues }: UpdateRequestVariables) =>
      updateRequest(requestId,formValues),
    onSuccess: (res) => {
      if(res.status === 204){
        toast.error('An error occured during request creation');
        setBlock(true);
      }
      else{
        toast.success('Request updated successfully');
        navigate('/requests');
      }
    },
    onError:(error)=>{
      setBlock(true);
      handleError(error);
    } 
  });

  const { mutate: deleteRequestMutation, isPending: isRequestDeleting } = useMutation({
    mutationFn: (requestId: string) => deleteRequest(requestId),
    onSuccess: () => {
      toast.success("Successfully moved to trash");
      navigate('/requests');
    },
    onError: (error) => handleError(error),
  });

  // On form submission
  const onSubmit: SubmitHandler<RequestFormValues> = (data) => {
    const updatedKeys = Object.keys(dirtyFields);
    if(updatedKeys.length === 0){
      toast.warn('There is nothing to update');
      return;
    }

    setBlock(false);
    let payload: any = {};
    let updatedPayload: any = {};

    (Object.keys(data) as (keyof RequestFormValues)[]).forEach((key)=>{
      if(updatedKeys.includes(key)){
        payload[key] = data[key];
      }
    });
    
      (Object.keys(payload) as (keyof RequestFormValues)[]).forEach((key) => {
        switch (key) {
          case 'valuation_reasons':
            updatedPayload[key] = payload[key].map((item:any) => item.id);
            break;
          case 'manager':
            updatedPayload[key] = payload[key].id;
            break;
          case 'collaborators':
            if (Array.isArray(payload[key]) && payload[key].length > 0) {
              updatedPayload[key] = payload[key].map((item:any) => item.id); // Extracting IDs
            } else {
              updatedPayload[key] = payload[key] || ''; // Set as empty string if it's an empty array or just return the string
            }
            break;
          default:
            updatedPayload[key] = payload[key];
        }
      });
    
      // console.log(updatedPayload);

      updateRequestMutation({formValues:updatedPayload,requestId:id!})
  };

  // Handle dialog cancel actions
  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleDelete = () => {
    deleteRequestMutation(id!);
  };

  const handleAddViewerSubmit = (val: IUserForm) => {
    setOpenAddViewerDialog(false);
  };

  useEffect(() => {
    setIsPageTransition(isLoading);
    if (requestData) {
      const {manager, collaborators, valuation_reasons, organization:{default_estimate_options}} = requestData;
      const res= {
        ...requestData,
        'default_estimate_options':VALUATION_TYPES.filter((val:any)=>default_estimate_options.includes(val.value)),
        'manager': {id:manager.id, label:manager.name, value:manager.id}, // Format as select option
        'collaborators': collaborators?.map((collaborator:any)=>({id:collaborator.id, label:collaborator.name,value:collaborator.id})),
        'valuation_reasons':EVALUATION_REASONS.filter(item=>valuation_reasons.includes(item.id))
      };
      reset(res);
    }
    return () => setIsPageTransition(false);
  }, [isLoading, requestData, reset, response, setIsPageTransition]);

useBlocker(() => {
  if (block && Object.keys(dirtyFields).length>0) {
    const confirmLeave = window.confirm(`You have unsaved changes. Are you sure you want to leave?`);
    return !confirmLeave; // return true to block navigation, false to allow it
  }
  return false; // Allow navigation if isDirty is false
});
  return (
    <Box sx={{ display: 'flex', alignItems: 'start', gap: '25px', width: '100%', overflow: 'auto', padding: theme => theme.spacing(3) }}>
      <Box sx={{ width: '100%', height: '100%', }}>
        <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <APDivider text='Who owns this equipment?' />
            <Box sx={{ display: 'flex', width: '50%', gap: 3, paddingTop: '15px' }}>
              <LabelInput
                id="ownerName"
                label="Customer Name*"
                name="customer_name"
                control={control}
                rules={{ validate: { isRequired } }}
              />
              <LabelInput
                id="ownerEmail"
                label="Customer email*"
                name="customer_email"
                control={control}
                rules={{ validate: { isEmail } }}
              />
            </Box>
          </Box>

          <Box>
            <APDivider text="Valuation details" />
            <Box sx={{ display: 'flex', width: '50%', gap: 3, paddingTop: '15px' }}>
              <LabelCheckboxInput
                label="Default valuation types"
                name="default_estimate_options"
                readOnly
                control={control}
                options={VALUATION_TYPES.filter((val:any)=>requestData?.organization.default_estimate_options.includes(val.value))}
              />
            </Box>
              <LabelCheckboxInput
                label="Other valuation types (Note: These are not default to your organization)"
                name="estimate_options"
                control={control}
                options={VALUATION_TYPES.filter((val:any)=>!requestData?.organization.default_estimate_options.includes(val.value))}
              />
              <RadioInput
                label="Select Process"
                name="process"
                control={control}
                options={PROCESS_DATA}
              />
              <RadioInput
                label="Capture type"
                name="capture_mode"
                control={control}
                options={CAPTURE_MODE_DATA}
              />
  
          </Box>

          <Box sx={{ marginTop: '8px' }}>
            <APDivider text='More information' />
            <Box sx={{ display: 'flex', width: '24.5%', paddingTop: '15px' }}>
              <SelectInput
                id='reasons'
                label='Select evaluation reasons'
                name='valuation_reasons'
                options={EVALUATION_REASONS}
                isMultiple
                control={control}
              />
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px' }}>
            <APDivider text='Notes' />
            <Box sx={{ width: '100%', paddingTop: '15px' }}>
              <RichTextInput id='notes' name='notes' control={control} />
            </Box>
          </Box>

          <Box sx={{ marginTop: '8px' }}>
            <APDivider text='Who is managing this request?' />
            <Box sx={{ width: '24.5%', paddingTop: '15px' }}>
              <SelectInput
                id="manager"
                label="Primary appraiser"
                name="manager"
                options={response?.data.map((user: IUser) => ({ id: user.id, label: user.name, value: user.id }))}
                control={control}
                isDisabled
                // rules={{ validate: { isRequired } }}
              />
              <SelectInput
                id="collaborators"
                label="Collaborators"
                name="collaborators"
                options={response?.data.map((user: IUser) => ({ id: user.id, label: user.name, value: user.id }))}
                control={control}
                isMultiple
                isDisabled={authUser?.role === 'appraiser'}
                
              />

              {/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <SelectInput
                  id="viewers"
                  label="Viewers"
                  name="viewers"
                  options={response?.data.filter((user: IUser) => user.role === 'guest').map((user: IUser) => ({ id: user.id, label: user.name, value: user.id }))}
                  control={control}
                  isMultiple
                />
                <Button
                  onClick={() => setOpenAddViewerDialog(true)}
                  sx={{ mt: '-10px', p: 0, fontSize: '12px', color: theme.palette.primary.main, borderBottom: `1px solid ${theme.palette.primary.main}`, borderRadius: 0 }}
                >
                  Add viewer
                </Button>
              </Box> */}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
            <Box>
              <Button type="submit" variant="contained" color="primary" disabled={isUpdating}>Save update</Button>
              <Button variant="outlined" sx={{ ml: 1 }} onClick={() => navigate('/requests')} disabled={isUpdating}>Cancel</Button>
            </Box>

            <Box>
              <APIconButton
                text='Delete Request'
                isRounded={false}
                color={'secondary'}
                icon={<Delete />}
                isDisabled={isUpdating}
                onClick={() => { setOpenDeleteDialog(true); }}
              />
            </Box>
          </Box>
        </ValidatorForm>
      </Box>

      <AddViewerForm open={openAddViewerDialog} onClose={() => setOpenAddViewerDialog(false)} onSubmit={handleAddViewerSubmit} />
      <DeleteConfirmationDialog
        title="Delete request"
        content={'Are you sure you want to move this request to trash?'}
        open={openDeleteDialog}
        onCancel={handleCancelDelete}
        onDelete={handleDelete}
        isDeleting={isRequestDeleting}
      />
    </Box>
  );
};

export default RequestEditForm;
