export const isRequired = (value: string | null | undefined) => 
  value ? true : 'This field is required';

export const isEmail = (value: string | null | undefined) => 
  !value || /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) || 'Invalid email format';

export const isUpperCase = (value: string | null | undefined) => 
  !value || /[A-Z]/.test(value) || 'Must contain an uppercase letter';

export const isLowerCase = (value: string | null | undefined) => 
  !value || /[a-z]/.test(value) || 'Must contain a lowercase letter';

export const hasNumber = (value: string | null | undefined) => 
  !value || /\d/.test(value) || 'Must contain a number';

export const hasSpecialChar = (value: string | null | undefined) => 
  !value || /[@$!%*?&]/.test(value) || 'Must contain a special character';

export const isMinLength = (minLength: number) => (value: string | null | undefined) => 
  !value || value.length >= minLength || `Must be at least ${minLength} characters long`;

export const isPasswordMatch = (password: string) => (value: string | null | undefined) => 
  !value || value === password || 'Passwords must match';

export const isOnlyDigits = (value: string | null | undefined) => 
  !value || /^\d+$/.test(value) || 'This field must contain digits only';

export const isValidName = (value: string | null | undefined) => 
  !value || /^[a-zA-Z\s]+$/.test(value) || 'Name must contain only letters and spaces';
