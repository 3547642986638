import { Box } from "@mui/material";
import { IColDef } from "../../components/table/tableInterfaces";
import APTable from "../../components/table/APTable";
import AdditionalInfoModal from "./AdditionalInfoModal";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IPagination, ITableData } from "../../interfaces";

interface Props{
    isLoading?: boolean;
    data: ITableData;
    rowPerPage?:number;
    onRowSelection?:(item:any,details:any) => void;
    onDelete?:(row:any)=>void;
    onPageChange?: (page:IPagination) => void;
}

const AssetsTable: React.FC<Props> = ({ isLoading, data, rowPerPage, onRowSelection, onDelete, onPageChange }) => {
    const navigate = useNavigate();
    const [selectedRow, setSelectedRow] = useState<any>();
    const [openAdditionalInfoModal, setOpenAdditionalInfoModal] = useState(false);

    // Define columns based on data
    const colDef: IColDef[] = useMemo(() => {
        let defaultColumns: IColDef[] = [
            { field: 'image', headerName: 'Image', type: 'imageText2', imageField: 'thumbnail', sortable: false, filterable: false },
            { field: 'year', headerName: 'Year', filterable: false, width: 100 },
            { field: 'make', headerName: 'Make', filterable: false },
            { field: 'model', headerName: 'Model', filterable: false },
            { field: 'category', headerName: 'Asset type', filterable: false },
            { field: 'serial', headerName: 'Serial', filterable: false, sortable: false },
            { field: 'sticker', headerName: 'Sticker ID', filterable: false, sortable: false },
        ];

        // Add dynamic columns based on 'estimate_options' in the data
        data.rowData[0]?.estimate_options.forEach((estimate: string) => {
            defaultColumns.push({
                field: estimate,
                headerName: estimate.toUpperCase(),
                filterable: false,
                sortable: false
            });
        });

        const base2 = [{ field: 'media_count', headerName: 'Media', filterable: false, sortable: false },
          { field: 'status', headerName: 'Status', type: 'status', filterable: false, sortable: false },
          { field: 'actions', headerName: 'Actions', type: 'action', filterable: false, width: 130 }
      ];

      base2.forEach((item: any) =>defaultColumns.push(item));

        return defaultColumns;
    }, [data.rowData]); // Recompute only when `data.rowData` changes

    const actions = [
        {
            label: 'Request additional info',
            action: (row: any) => {
                setSelectedRow(row);
                setOpenAdditionalInfoModal(true);
            }
        },
        {
            label: 'Compare with others',
            action: (row: any) => {
                // navigate(`/compare/${row.id}`);
            }
        },
        {
            label: 'Generate report',
            action: (row: any) => {}
        },
        {
            label: 'Edit asset',
            action: (row: any) => {
                navigate(`/edit-asset/${row.id}`);
            }
        },
        {
            label: 'MOVE ASSET TO TRASH',
            action: (row: any) => {
                if (onDelete) onDelete(row);
            }
        }
    ];

    const clickableFields = ['image', 'year', 'make', 'model', 'category', 'serial', 'sticker', 'miles', 'media_count', 'status'];

    const handleRowClick = (row: any) => {
        if (clickableFields.includes(row.field)) {
            navigate(`/edit-asset/${row.data.id}`);
        }
    };

    const handleOnCancel = () => {
        setSelectedRow(null);
        setOpenAdditionalInfoModal(false);
    };

    // Filter and add the status to the data based on conditions
    const filteredData = data.rowData.map((item) => {
        // let status = '';
        // Assign appropriate status logic here if needed

        return { ...item }; // Example of status addition or filtering logic
    });

    return (
        <Box>
            <APTable
                showCheckbox
                columns={colDef}
                data={{ rowData: filteredData, rowCount: data.rowCount }}
                actions={actions}
                clickableFields={clickableFields}
                rowPerPage={10}
                onRowSelection={onRowSelection}
                onRowClick={handleRowClick}
                isLoading={isLoading}
                rowHeight={90}
                onPageChange={onPageChange}
            />
            <AdditionalInfoModal open={openAdditionalInfoModal} row={selectedRow} onCancel={handleOnCancel} />
        </Box>
    );
};

export default AssetsTable;
